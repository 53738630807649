@use '@angular/material' as mat;

:root {
  --scealai-green: #70a87f;
  --scealai-med-green: #437250;
  --scealai-dark-green: #304e38;
  --scealai-light-green: #91ffa5;
  --scealai-yellow: #e5bd3b;
  --scealai-yellow-hover: #ad8f2b;
  --scealai-orange: #c15a2e;
  --scealai-cream: #f1e9d8;
  --scealai-cream-2: #f7f2e7;
  --scealai-light-gray: #999999;
  --scealai-dark-gray: #424242;
  --scealai-light-brown: #b3a995;
  --scealai-med-brown: #534d3d;
  --scealai-med-brown-hover: #3b372b;
  --scealai-light-blue: #0088ff6b;
  --scealai-light-blue-hover: #0088ff36;
  --scealai-med-blue: #8fcaff;
  --scealai-gray: #d1d1d1;
  --orange-text: #ff9100;
  --dark-text: #261500;
  --form-background: #e6e8ed;
  --modal-hover: #707070;
  --delete-red: #dd000e;
  --delete-red-hover: #b7000a;
  --notification-button: #bd2323;
  --highlight-error-red: rgb(255, 163, 163);
  //--paper-img: url(https://www.abair.ie/scealai/assets/img/paperBg.jpg) no-repeat center fixed;
}

html,body.rootBody {
  height: 100%;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

html {
  overflow-y: hidden;
}

body.rootBody {
   //background: url("https://www.abair.ie/scealai/assets/img/paperBg.jpg") no-repeat center fixed;
   -webkit-background-size: cover;
   -moz-background-size: cover;
   -o-background-size: cover;
   background-size: cover;
   font-family: "Open Sans";
   overflow-y: scroll;
}

//////////////////////////////////////////////////////////////////////////////// CONTAINERS / HEADERS
.bookImg {
  z-index: -10;
  width: 90%;
  height: 500px;
  position: fixed;
  right: 5%;
}

.bookContentsContainer {
    width: 80%;
    margin-left: 10%;
    z-index: 10;
}

.page {
    padding-top: 20px;
}

.container {
    width: 900px;
    background-color: #FAFAFA;
    border-radius: 4px;
    padding-top: 20px;
    margin-bottom: 20px;
    -webkit-box-shadow: -1px 5px 32px 10px rgba(0,0,0,0.2);
    -moz-box-shadow: -1px 5px 32px 10px rgba(0,0,0,0.2);
    box-shadow: -1px 5px 32px 10px rgba(0,0,0,0.2);
}

.header {
    width: 100%;
    font-size: 20pt;
    height: 50px;
    margin-bottom: 10px;
}

.contentContainer {
    padding-left: 5%;
    padding-right: 5%;
}

.adminContent {
  width: 100%;
  padding: 3vh;
  display: flex;
}

.aboutContent {
    text-align: left;
}

.contentBody {
    display: flex;
    width: 100%;
}

.headerTitle {
    font-size: 14pt;
    font-weight: bold;
}

.classContainer {
    width: 100%;
    background-color: white;
    border-radius: 5px;
    height: 80vh;
    -webkit-box-shadow: -1px 5px 32px 10px rgba(0,0,0,0.2);
    -moz-box-shadow: -1px 5px 32px 10px rgba(0,0,0,0.2);
    box-shadow: -1px 5px 32px 10px rgba(0,0,0,0.2);
}

.classHeader {
    width: 100%;
    background-color: var(--scealai-dark-green);
    border-radius: 5px 5px 0px 0px;
    color: white;
    display: flex;
    padding: 8px;
    padding-left: 16px;
    font-size: 16pt;
}

.classBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    padding-top: 0px;
    overflow: auto;
    height: 67.5vh;
}

.panelContent {
  padding-top: 20px; 
  font-size: 13pt; 
  font-family: 'Serif';
}

//////////////////////////////////////////////////////////////////////////////// RECORDINGS / SYNTHESIS

.bookContainerSynth {
  width: 70%;
  margin-left: 15%;
  z-index: 10;
  border-radius: 5px;
  background: url("https://www.abair.ie/scealai/assets/img/paperBg.jpg") no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  -webkit-box-shadow: -1px 5px 32px 10px rgba(0,0,0,0.2);
  -moz-box-shadow: -1px 5px 32px 10px rgba(0,0,0,0.2);
  box-shadow: -1px 5px 32px 10px rgba(0,0,0,0.2);
}


.recordingStoryHeader {
    background-color: var(--scealai-green);
    color: white;
    border-radius: 4px 4px 0px 0px;
    width: 90%;
    margin-left: 5%;
}

.textContainerSynth {
  width: 90%;
  margin-left: 5%;
  // height: 290px;
  color: var(--dark-text);
  background-color: #F8F9FA;
  border: 1px solid var(--dark-text)a2;
  text-align: left;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  overflow: auto;
}

.dividerLine {
  border-top: 1px solid var(--modal-hover);
  width: 100%;
}

.changeSplitBtn {
  background-color: var(--scealai-green);
  border-radius: 4px;
  margin-right: 5px;
  color: white;
}

.changeSplitBtn:hover {
  background-color: var(--scealai-med-green);
}

/**
 * =======================
 * ASR Loading Dot 
 * =======================
 */
 .dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--scealai-green);
  color: var(--scealai-green);
  margin-left: 20px;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: .5s;
}

.dot-flashing::before, .dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--scealai-green);
  color: var(--scealai-green);
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--scealai-green);
  color: var(--scealai-green);
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: var(--scealai-green);
  }
  50%,
  100% {
    background-color: #d1e6d6;
  }
}

@-webkit-keyframes pulse {
	0% { opacity: 1; }
	50% { opacity: 0; }
	100% { opacity: 1; }
}
@-moz-keyframes pulse {
	0% { opacity: 1; }
	50% { opacity: 0; }
	100% { opacity: 1; }
}
@-o-keyframes pulse {
	0% { opacity: 1; }
	50% { opacity: 0; }
	100% { opacity: 1; }
}
@-ms-keyframes pulse {
	0% { opacity: 1; }
	50% { opacity: 0; }
	100% { opacity: 1; }
}
@keyframes pulse {
	0% { opacity: 1; }
	50% { opacity: 0; }
	100% { opacity: 1; }
}

//////////////////////////////////////////////////////////////////////////////// TEACHER STYLES
.classroomCard {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    color: white;
    font-size: 14pt;
    background-color: var(--scealai-med-brown);
    border-radius: 5px;
    margin-top: 5px;
}

.classroomCard:hover {
    color: var(--orange-text);
    background-color: var(--scealai-med-brown-hover);
    transition: color, background-color, 0.5s;
    cursor: pointer;
}

.classroomKey {
    display: flex;
    padding-left: 16px;
    padding-right: 16px;
    background-color: var(--scealai-gray);
    color: var(--scealai-dark-gray);
}

//////////////////////////////////////////////////////////////////////////////// ADMIN / STUDENT STYLES

.adminLoaderContainer {
    position: absolute;
    width: 90%;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loaderContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    color: var(--scealai-med-green);
}

.contentsTitleContainer {
    width: 100%;
    padding: 5px;
    display:flex;
    justify-content: space-between;
}

.contentsDateTitle {
    font-size: 14pt;
    color: var(--scealai-orange);
}

.contentsLinkTitle {
    float: left;
    margin-left: 1%;
    font-size: 14pt;
    color: var(--scealai-orange);
}

.contentSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

//////////////////////////////////////////////////////////////////////////////// BUTTONS

.deleteCheckbox {
    float:left;
    margin-right: 5px;
    margin-top: 5px;
}

.notificationBtn {
    margin-left: 5px;
    background-color: #bd2323;
    color: white;
    font-size: 8pt;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-box-shadow: inset 0px -9px 9px -14px rgba(0,0,0,0.75);
    -moz-box-shadow: inset 0px -9px 9px -14px rgba(0,0,0,0.75);
    box-shadow: inset 0px -9px 9px -14px rgba(0,0,0,0.75);
}

.notificationBtn:hover {
  cursor: pointer;
  background-color: #d82727;
}

.btn {
  color: white;
  font-size: 14pt;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 30px;
  background-color: var(--scealai-yellow);
  z-index: 20;
}

// .btn:hover {
//   background-color: var(--scealai-yellow-hover);
//   color: white;
// }

.deleteRedBtn {
    background-color: var(--delete-red);
    color: white;
}

.deleteRedBtn:hover {
    background-color: var(--delete-red-hover);
}

.createBtnGreen {
    background-color: var(--scealai-green);
    color: white;
}

.createBtnGreen:hover {
    background-color: var(--scealai-dark-green);
}

.greenBtn {
  font-size: 12pt;
  background-color: var(--scealai-green);
  border-radius: 5px;
  padding: 4px;
}

.greenBtn:hover {
  cursor: pointer;
  color: var(--scealai-light-green);
  -webkit-transition: 0.5s; /* Safari */
  transition: 0.5s;
}

.languageButton {
    background-color: var(--scealai-green);
    color: white;
    padding-left: 6px;
    padding-right: 6px;
    border-radius: 4px;
    margin: 4px;
}

.languageButton:hover { 
    cursor: pointer;
    background-color: var(--scealai-med-green);
}

.languageButtonSelected {
    background-color: var(--scealai-med-green);
}

.backArrow {
    margin-right: 8px;
}

.backArrow:hover {
    cursor: pointer;
    color: var(--orange-text);
    transition: color, 0.5s;
}

//////////////////////////////////////////////////////////////////////////////// MODALS

.modalContainer {
    position: absolute;
    z-index: 5;
    width: 90%;
    height: 500px;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    text-align: center;
}

.modalContent {
    padding: 25px;
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    -webkit-box-shadow: -1px 10px 17px 0px rgba(0,0,0,0.5);
    -moz-box-shadow: -1px 10px 17px 0px rgba(0,0,0,0.5);
    box-shadow: -1px 10px 17px 0px rgba(0,0,0,0.5);
}

.modalBtn {
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border: none;
    border-radius: 5px;
    margin: 5px;
    margin-top: 15px;
}

.modalText {
    font-size: 14pt;
    white-space: normal;
    color: black;
}

.hidden {
    visibility: hidden;
    opacity: 0;
}

.hiddenFade {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s, opacity 0.3s ease-in;
}

.visible {
    visibility: visible;
    opacity: 1;
}

.visibleFade {
    visibility: visible;
    opacity: 1;
    transition: visibility 0.3s, opacity 0.3s ease-out;
}

//////////////////////////////////////////////////////////////////////////////// PROFILE / REGISTRATION

.errorMessage {
  color: red;
  margin-top: 10px;
}

.usernameErrorText {
    font-size: 12pt;
    color: red;
    margin-top: 4px;
    width: 80%;
    margin-left: 10%;
}

.userInfoTextarea {
    height: 30px;
    margin-left: 10px;
    padding-left: 5px;
    font-size: 12pt;
    resize: none;
    background-color: white;
    border: none;
    border-radius: 5px;
    white-space: nowrap;
}

.registerContainer {
    width: 400px;
    display: inline-block;
    color: white;
    border-radius: 10px;
    -webkit-box-shadow: -1px 5px 32px 10px rgba(0,0,0,0.2);
    -moz-box-shadow: -1px 5px 32px 10px rgba(0,0,0,0.2);
    box-shadow: -1px 5px 32px 10px rgba(0,0,0,0.2);
}

.formTitle {
    margin-bottom: -20px;
    margin-top: 5px;
    font-size: 18pt;
}

.formContainer {
    width: 400px;
    display: inline-block;
    padding-top: 15px;
    padding-bottom: 25px;
    color: var(--dark-text);
    background-color: var(--form-background);
    border-radius: 0px 0px 10px 10px;
}

//////////////////////////////////////////////////////////////////////////////// ICONS / LOGOS

.headerIconContainer {
    margin-left: auto;
    display: flex;
    overflow-x: auto;
}

.headerIconBtn {
    font-size: 12pt;
    margin-left: 8px;
    background-color: var(--scealai-green);
    color: white;
    border-radius: 5px;
    padding: 4px;
    padding-left: 8px;
    padding-right: 8px;
}

.headerIconBtn:hover {
    cursor: pointer;
    color: var(--scealai-light-green);
    -webkit-transition: 0.5s; /* Safari */
    transition: 0.5s;
}

.headerIcon {
    margin-left: 5px;
}

button:focus, input:focus, audio:focus{
  outline: 0;
}

.footer {
  margin-left: 5%;
  width: 90%;
  display: flex;
}

.logoContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-top: 20px;
    padding-bottom: 20px;
}

.sponsorCard {
    display: flex;
    flex-direction: column;
}

.cardBody {
    display: flex;
    align-items: center;
}

.cardLeft {
    width: 60%;
    margin-right: 5%;
}

.cardRight {
    width: 35%;
}

.logoCardImg {
    width: 100%;
}

.logoImg {
    height: 80px;
    margin: 10px;
}

.textBlock {
    padding: 20px;
}

.smallTextBlock {
    padding: 10px;
}

.testimonialFooter {
    width: 100%;
    text-align: right;
}

.greenLinkText {
    margin-top: 10px;
    color: var(--scealai-green);
    text-decoration: underline;
}

.greenLinkText:hover {
    cursor: pointer;
}

.recordingIcon {
    font-size: 14pt;
    margin-left: 10px;
    color: var(--delete-red);
    animation: blinker 1.5s cubic-bezier(.5, 0, 1, 1) infinite alternate;  
}

@keyframes blinker {  
  from { opacity: 1; }
  to { opacity: 0; }
}

@-webkit-keyframes blinker {  
    from { opacity: 1; }
    to { opacity: 0; }
  }

//////////////////////////////////////////////////////////////////////////////// QUILL STYLES
.ql-container {
  margin: auto;
}

.ql-editor {
  background-color: white;
  font-size: 14pt;
  max-width: 210mm;
  box-shadow: 0 0 0.3em 0.3em hsla(0,0%,0%,0.2);
  margin: auto;
  border-left: 0.5px solid white;
  border-right: 0.5px solid white;
  @media only screen and (min-width: 210mm) {
    padding-left: 30mm !important;
    padding-right: 30mm !important;
  }
  * {
    padding-top:    0.5em !important;
    padding-bottom: 0.5em !important;
    max-width: 150mm;
    margin: auto !important;
  }
  span {
    padding-top:    0.1em !important;
    padding-bottom: 0.1em !important;
  }
}

//////////////////////////////////////////////////////////////////////////////// Chatbot
.add-personal-topics{
  position: relative;
  border-radius: 5px;
  outline: none;
  border: none;
  color: white;
  background-color: #1ABC9C;
  height: 21%;
  width: 28%;
  margin-top: 5%;
  margin-left: 4%;
  font-size: 14px;
  display: inline;
}

.add-personal-topics:hover{
  cursor: pointer;
  font-style: italic;
}

/* Dialogue and Photos */
.bot-message-photo, .user-message-photo{
  position:relative;
  width: 45px;
  height: 45px;
  background: #FEF9E7;
  border-radius: 50%;
  overflow: hidden;
  margin-top: 0%;
}

.bot-message-photo{
  float: left;
}

.user-message-photo{
  display: flexbox;
  margin-left: 93.5%;
}

#bot-img, #user-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

#user-img{
  width: 90%;
  height: 90%;
  object-fit: contain;
}

.message_parent{
  animation: message 0.15s ease-out 0s forwards;
}

.user-message, .bot-message{
  width: 75%;
  padding: 10px;
  border-radius: 10px;
  font-size: 14px;
  margin-top: 1%;
}

.message_parent:hover{
  cursor: pointer;
}

.user-message{
  background: #58D68D;
  margin-left: 17%;
  margin-top: -6%;
}

.bot-message{
  background: #fff;
  box-shadow: 1px 1px 1px 0px #ccc;
  margin-left: 8%;
}

.bot-message-ind{
  position: relative;
  background: #fff;
  box-shadow: 1px 1px 1px 0px #ccc;
  width: 50px;
  padding: 10px;
  border-radius: 10px;
  font-size: 14px;
  margin-top: 1%;
  margin-left: 8%;
}

.controls-popup{
  padding: 2px;
  right: 1.8%;
  width: 13%;
}

.controls{
  color: black;
  text-align: center;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  background: #95A5A6;
  box-shadow: 1px 1px 1px 0px #ccc;
  top: 3%;
}

.manual-pause:hover, .manual-play:hover{
  cursor: pointer;
}

.manual-play{
  margin-left: 8%;
}

@keyframes message {
  0% {
    max-height: 100vmax;
  }
  80% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
    max-height: 100vmax;
    overflow: visible;
  }
}

/* Typing Indicator */
.typing-indicator{
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  margin-bottom: 10px;
}

.typing-indicator .chat-message{
  width: 60px;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  font-size: 14px;
  background: #fff;
  box-shadow: 1px 1px 1px 0px #ccc;
}

#typ1{
  width: 8px;
  height: 8px;
  background-color: #BDC3C7;
  display: inline-block;
  border-radius: 50%;
  animation: bounce 1.2s infinite;
}

#typ2{
  width: 8px;
  height: 8px;
  background-color: #BDC3C7;
  display: inline-block;
  margin: 1px;
  border-radius: 50%;
  animation: bounce 1.2s infinite .3s;
}

#typ3{
  width: 8px;
  height: 8px;
  background-color: #BDC3C7;
  display: inline-block;
  margin: 1px;
  border-radius: 50%;
  animation: bounce 1.2s infinite .6s;
}

@keyframes bounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
  }
}

/* RiveScript buttons */
.rive-button{
  border-radius: 15px;
  background-color: #E74C3C;
  padding: 5px;
  border-style: none;
  color: white;
  margin: 5px;
  padding: 10px;
}

.rive-button:hover{
  background-color: #F1C40F;
}

.rive-button2{
  border-radius: 15px;
  padding: 10px;
  border-style: none;
  color: white;
  margin: 5px;
  background-color: #8E44AD;
}

.rive-button2:hover{
  background-color: #1ABC9C;
}

#rive-checkbox{
  position: relative;
  top: 3px;
}

.dictButton{
  position: relative;
  display: none;
  left: 275px;
  width: 25px;
  height: 25px;
  top: -11px;
}

.dictButton:hover{
  cursor:pointer;
}

.dictPopup{
  display: none;
  position: absolute;
  padding: 10px;
  font-family: sans-serif;
  text-align: center;
  background: #F7DC6F;
  width: 220px;
  height: 150px;
  margin-top: 330px;
  right: 320px;
  border-radius: 8px;
}

.new-Q{
  position: relative;
  margin-top: 5%;
  margin-left: 5%;
}

#contents-2{
  left: 35%;
}

#contents-3{
  left: 67%;
}

#contents-4{
  margin-top: 20%;
}

.A{
  position: relative;
  width: 40%;
  margin-left: 4%;
}

.add-A{
  position: relative;
  left: 2%;
  outline: none;
  border: none;
  font-size: smaller;
  border-radius: 5px;
  background-color: #F5B041;
}

.add-A:hover{
  cursor: pointer;
  background-color: #F1C40F;
}

#send-recording{
  position: absolute;
  outline: none;
  border: none;
  border-radius: 5px;
  bottom: 5%;
  left: 28%;
  font-size: 14px;
}

#send-recording:hover{
  font-style: italic;
  cursor: pointer;
}

.deleteQPrompt, .deleteAPrompt{
  position: absolute;
  outline: none;
  border: none;
  font-size: small;
  left: 62.5%;
  top: 0%;
}

.deleteAPrompt{
  margin-left: 27%;
}

.deleteQPrompt:hover, .deleteAPrompt:hover{
  background-color: #BDC3C7;
}

@media only screen and (min-width: 500px) and (max-width: 800px){
  .bot-message{
    margin-left: 30%;
  }
  .user-message-photo{
    margin-left: 90%;
  }
  .user-message{
    margin-left: 12%;
    margin-top: -9%;
  }
  .bot-message-photo, .user-message-photo{
    width: 40px;
    height: 40px;
  }
  .manual-play{
    margin-left: 0%;
  }
}

@media only screen and (min-width: 800px) and (max-width: 900px){
  .bot-message{
    left: 20%;
  }
  .manual-play{
    margin-left: 0%;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1040px){
  .bot-message{
    margin-left: 10%;
  }
}
